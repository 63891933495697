/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string; }
  /** Represents untyped JSON */
  JSON: { input: any; output: any; }
};

export type Attachment = {
  __typename?: 'Attachment';
  aspectRatio?: Maybe<Scalars['String']['output']>;
  attachmentType: AttachmentType;
  createdAt: Scalars['ISO8601DateTime']['output'];
  giphy?: Maybe<Scalars['JSON']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  muxPlaybackId?: Maybe<Scalars['String']['output']>;
  playbackUrl?: Maybe<Scalars['String']['output']>;
  transcodingState: TranscodingState;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  voiceNoteUrl?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

/** Autogenerated input type of AttachmentCreate */
export type AttachmentCreateInput = {
  attachmentType: AttachmentType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  giphy?: InputMaybe<Scalars['JSON']['input']>;
  imageSignedId?: InputMaybe<Scalars['String']['input']>;
  voiceNoteSignedId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AttachmentCreate. */
export type AttachmentCreatePayload = {
  __typename?: 'AttachmentCreatePayload';
  attachment: Attachment;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  muxDirectUploadUrl?: Maybe<Scalars['String']['output']>;
};

export enum AttachmentType {
  Audio = 'AUDIO',
  Giphy = 'GIPHY',
  Image = 'IMAGE',
  Video = 'VIDEO'
}

/** Auth type */
export enum AuthType {
  Session = 'SESSION',
  Token = 'TOKEN'
}

export type AuthorizationResult = {
  __typename?: 'AuthorizationResult';
  /** Human-readable error message */
  message?: Maybe<Scalars['String']['output']>;
  /** Reasons of check failure */
  reasons?: Maybe<FailureReasons>;
  /** Result of applying a policy rule */
  value: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CredentialsUserAuth */
export type CredentialsUserAuthInput = {
  authType?: InputMaybe<AuthType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  rememberMe: Scalars['Boolean']['input'];
};

/** Autogenerated return type of CredentialsUserAuth. */
export type CredentialsUserAuthPayload = {
  __typename?: 'CredentialsUserAuthPayload';
  authToken?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  csrfToken: Scalars['String']['output'];
  user?: Maybe<User>;
};

/** Autogenerated input type of EmailTokenUserAuth */
export type EmailTokenUserAuthInput = {
  authType?: InputMaybe<AuthType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated return type of EmailTokenUserAuth. */
export type EmailTokenUserAuthPayload = {
  __typename?: 'EmailTokenUserAuthPayload';
  authToken?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  csrfToken: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of EmailUserAuthChallenge */
export type EmailUserAuthChallengeInput = {
  clientAuthCode: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of EmailUserAuthChallenge. */
export type EmailUserAuthChallengePayload = {
  __typename?: 'EmailUserAuthChallengePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type FailureReasons = {
  __typename?: 'FailureReasons';
  /** JSON-encoded map of reasons */
  details: Scalars['String']['output'];
  /** Human-readable errors */
  fullMessages: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of Logout */
export type LogoutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of Logout. */
export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of MarkRead */
export type MarkReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readMarks: Array<ReadMarkInput>;
};

/** Autogenerated return type of MarkRead. */
export type MarkReadPayload = {
  __typename?: 'MarkReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages: Array<Message>;
  rooms: Array<Room>;
  viewer: User;
};

export type Message = Node & {
  __typename?: 'Message';
  attachments: Array<Attachment>;
  childMessageUsers: Array<User>;
  childMessagesCount: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  editedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  latestChildMessage?: Maybe<Message>;
  parentMessageId?: Maybe<Scalars['ID']['output']>;
  quotedMessage?: Maybe<Message>;
  reactionGroups: Array<ReactionGroup>;
  room?: Maybe<Room>;
  textContent?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<User>;
  userIsRoomMember: Scalars['Boolean']['output'];
  viewerCanDelete: AuthorizationResult;
  viewerCanUpdate: AuthorizationResult;
  viewerHasRead: Scalars['Boolean']['output'];
};

/** The connection type for Message. */
export type MessageConnection = {
  __typename?: 'MessageConnection';
  /** A list of edges. */
  edges: Array<MessageEdge>;
  /** A list of nodes. */
  nodes: Array<Message>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of MessageCreate */
export type MessageCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageInput: MessageInput;
  parentMessageId?: InputMaybe<Scalars['ID']['input']>;
  roomId: Scalars['ID']['input'];
};

/** Autogenerated return type of MessageCreate. */
export type MessageCreatePayload = {
  __typename?: 'MessageCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  message: Message;
};

/** Autogenerated input type of MessageDelete */
export type MessageDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of MessageDelete. */
export type MessageDeletePayload = {
  __typename?: 'MessageDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  message: Message;
};

/** An edge in a connection. */
export type MessageEdge = {
  __typename?: 'MessageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Message;
};

/** Autogenerated return type of MessageEvent. */
export type MessageEventPayload = {
  __typename?: 'MessageEventPayload';
  messageChildrenUpdated?: Maybe<Message>;
  messageCreated?: Maybe<Message>;
  messageReactionsUpdated?: Maybe<Message>;
  messageUpdated?: Maybe<Message>;
};

export type MessageInput = {
  attachmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  quotedMessageId?: InputMaybe<Scalars['ID']['input']>;
  textContent?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of MessageUpdate */
export type MessageUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  messageInput: MessageInput;
};

/** Autogenerated return type of MessageUpdate. */
export type MessageUpdatePayload = {
  __typename?: 'MessageUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  message: Message;
};

export enum MessagesSort {
  CreatedAtDesc = 'CREATED_AT_DESC',
  Engagement = 'ENGAGEMENT'
}

export type Mutation = {
  __typename?: 'Mutation';
  attachmentCreate: AttachmentCreatePayload;
  credentialsUserAuth: CredentialsUserAuthPayload;
  /** Authenticate a user with an email token */
  emailTokenUserAuth: EmailTokenUserAuthPayload;
  /** Send an email to the user with a challenge code */
  emailUserAuthChallenge: EmailUserAuthChallengePayload;
  logout: LogoutPayload;
  markRead: MarkReadPayload;
  /** Creates a new message */
  messageCreate: MessageCreatePayload;
  /** Deletes a message by ID */
  messageDelete: MessageDeletePayload;
  /** Updates a message by id */
  messageUpdate: MessageUpdatePayload;
  /** Updates a user's notification config */
  notificationConfigUpdate: NotificationConfigUpdatePayload;
  /** Creates a new reaction */
  reactionCreate: ReactionCreatePayload;
  reactionDelete: ReactionDeletePayload;
  roomCreate: RoomCreatePayload;
  roomFollow: RoomFollowPayload;
  roomJoin: RoomJoinPayload;
  roomLeave: RoomLeavePayload;
  roomRemoveMember: RoomRemoveMemberPayload;
  roomUnfollow: RoomUnfollowPayload;
  roomUpdate: RoomUpdatePayload;
  setTypingIndication: SetTypingIndicationPayload;
  smsUpdatePhone: SmsUpdatePhonePayload;
  smsUserAuth: SmsUserAuthPayload;
  /** Send a sms to the user with a challenge code */
  smsUserAuthChallenge: SmsUserAuthChallengePayload;
  uploadPresign: UploadPresignPayload;
  /** Onboards a user */
  userOnboard: UserOnboardPayload;
  /** Updates a user by id */
  userUpdate: UserUpdatePayload;
};


export type MutationAttachmentCreateArgs = {
  input: AttachmentCreateInput;
};


export type MutationCredentialsUserAuthArgs = {
  input: CredentialsUserAuthInput;
};


export type MutationEmailTokenUserAuthArgs = {
  input: EmailTokenUserAuthInput;
};


export type MutationEmailUserAuthChallengeArgs = {
  input: EmailUserAuthChallengeInput;
};


export type MutationLogoutArgs = {
  input: LogoutInput;
};


export type MutationMarkReadArgs = {
  input: MarkReadInput;
};


export type MutationMessageCreateArgs = {
  input: MessageCreateInput;
};


export type MutationMessageDeleteArgs = {
  input: MessageDeleteInput;
};


export type MutationMessageUpdateArgs = {
  input: MessageUpdateInput;
};


export type MutationNotificationConfigUpdateArgs = {
  input: NotificationConfigUpdateInput;
};


export type MutationReactionCreateArgs = {
  input: ReactionCreateInput;
};


export type MutationReactionDeleteArgs = {
  input: ReactionDeleteInput;
};


export type MutationRoomCreateArgs = {
  input: RoomCreateInput;
};


export type MutationRoomFollowArgs = {
  input: RoomFollowInput;
};


export type MutationRoomJoinArgs = {
  input: RoomJoinInput;
};


export type MutationRoomLeaveArgs = {
  input: RoomLeaveInput;
};


export type MutationRoomRemoveMemberArgs = {
  input: RoomRemoveMemberInput;
};


export type MutationRoomUnfollowArgs = {
  input: RoomUnfollowInput;
};


export type MutationRoomUpdateArgs = {
  input: RoomUpdateInput;
};


export type MutationSetTypingIndicationArgs = {
  input: SetTypingIndicationInput;
};


export type MutationSmsUpdatePhoneArgs = {
  input: SmsUpdatePhoneInput;
};


export type MutationSmsUserAuthArgs = {
  input: SmsUserAuthInput;
};


export type MutationSmsUserAuthChallengeArgs = {
  input: SmsUserAuthChallengeInput;
};


export type MutationUploadPresignArgs = {
  input: UploadPresignInput;
};


export type MutationUserOnboardArgs = {
  input: UserOnboardInput;
};


export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID']['output'];
};

export type NotificationConfig = Node & {
  __typename?: 'NotificationConfig';
  asList?: Maybe<Array<NotificationConfigListItem>>;
  commentMention: Scalars['Boolean']['output'];
  followerCommentReaction: Scalars['Boolean']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
  messageMention: Scalars['Boolean']['output'];
  roomMessage: Scalars['Boolean']['output'];
};

export type NotificationConfigCategory = {
  __typename?: 'NotificationConfigCategory';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

/** Input type for updating notification config */
export type NotificationConfigInput = {
  commentMention?: InputMaybe<Scalars['Boolean']['input']>;
  followerCommentReaction?: InputMaybe<Scalars['Boolean']['input']>;
  messageMention?: InputMaybe<Scalars['Boolean']['input']>;
  roomMessage?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationConfigListItem = {
  __typename?: 'NotificationConfigListItem';
  category: NotificationConfigCategory;
  notifications: Array<NotificationConfigNotification>;
};

export type NotificationConfigNotification = {
  __typename?: 'NotificationConfigNotification';
  description: Scalars['String']['output'];
  fieldName: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** Autogenerated input type of NotificationConfigUpdate */
export type NotificationConfigUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  notificationConfigInput: NotificationConfigInput;
};

/** Autogenerated return type of NotificationConfigUpdate. */
export type NotificationConfigUpdatePayload = {
  __typename?: 'NotificationConfigUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  notificationConfig: NotificationConfig;
};

/** Autogenerated return type of Notifications. */
export type NotificationsPayload = {
  __typename?: 'NotificationsPayload';
  currentUser?: Maybe<User>;
  roomUpdated?: Maybe<Room>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum ProfilePictureColor {
  Blue = 'BLUE',
  Clay = 'CLAY',
  Cream = 'CREAM',
  Green = 'GREEN',
  Lime = 'LIME',
  Magenta = 'MAGENTA',
  Midnight = 'MIDNIGHT',
  Moss = 'MOSS',
  Orange = 'ORANGE',
  Pink = 'PINK',
  Purple = 'PURPLE',
  Red = 'RED',
  Sky = 'SKY',
  SoftLime = 'SOFT_LIME',
  Yellow = 'YELLOW'
}

export type Query = {
  __typename?: 'Query';
  /** Fetches a message given its ID */
  message: Message;
  messages: MessageConnection;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Fetches a list of objects given a list of IDs. */
  nodes: Array<Node>;
  randomMessage: Message;
  reactions: ReactionConnection;
  /** Fetches a room given its ID */
  room: Room;
  rooms: RoomConnection;
  /** Fetches a user given its ID */
  user: User;
  users: UserConnection;
  viewer?: Maybe<User>;
};


export type QueryMessageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  centeredOnId?: InputMaybe<Scalars['ID']['input']>;
  centeredOnOldestUnread?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  parentMessageId?: InputMaybe<Scalars['ID']['input']>;
  roomId?: InputMaybe<Scalars['ID']['input']>;
  sort?: InputMaybe<MessagesSort>;
};


export type QueryNodeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryNodesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryReactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  messageId: Scalars['ID']['input'];
};


export type QueryRoomArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRoomsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  roomUserFilter?: InputMaybe<RoomUserFilterInput>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  membershipRoomId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type Reaction = {
  __typename?: 'Reaction';
  createdAt: Scalars['ISO8601DateTime']['output'];
  emoji: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: User;
};

/** The connection type for Reaction. */
export type ReactionConnection = {
  __typename?: 'ReactionConnection';
  /** A list of edges. */
  edges: Array<ReactionEdge>;
  /** A list of nodes. */
  nodes: Array<Reaction>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of ReactionCreate */
export type ReactionCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emoji: Scalars['String']['input'];
  messageId: Scalars['ID']['input'];
};

/** Autogenerated return type of ReactionCreate. */
export type ReactionCreatePayload = {
  __typename?: 'ReactionCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  message: Message;
};

/** Autogenerated input type of ReactionDelete */
export type ReactionDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emoji: Scalars['String']['input'];
  messageId: Scalars['ID']['input'];
};

/** Autogenerated return type of ReactionDelete. */
export type ReactionDeletePayload = {
  __typename?: 'ReactionDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  message: Message;
};

/** An edge in a connection. */
export type ReactionEdge = {
  __typename?: 'ReactionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Reaction;
};

export type ReactionGroup = {
  __typename?: 'ReactionGroup';
  emoji: Scalars['String']['output'];
  id: Scalars['String']['output'];
  reactionCount: Scalars['Int']['output'];
  viewerReacted: Scalars['Boolean']['output'];
};

export type ReadMarkInput = {
  messageId?: InputMaybe<Scalars['ID']['input']>;
  roomId?: InputMaybe<Scalars['ID']['input']>;
  timestamp: Scalars['ISO8601DateTime']['input'];
};

export type Room = Node & {
  __typename?: 'Room';
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  isViewerFollower: Scalars['Boolean']['output'];
  isViewerMember: Scalars['Boolean']['output'];
  latestMessage?: Maybe<Message>;
  name: Scalars['String']['output'];
  photoUrl?: Maybe<Scalars['String']['output']>;
  roomStatus: RoomStatus;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  viewerReadAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  viewerUnreadMessagesCount?: Maybe<Scalars['Int']['output']>;
};

/** RoomAssociationType type */
export enum RoomAssociation {
  Follower = 'FOLLOWER',
  Member = 'MEMBER'
}

/** The connection type for Room. */
export type RoomConnection = {
  __typename?: 'RoomConnection';
  /** A list of edges. */
  edges: Array<RoomEdge>;
  /** A list of nodes. */
  nodes: Array<Room>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of RoomCreate */
export type RoomCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roomInput: RoomInput;
};

/** Autogenerated return type of RoomCreate. */
export type RoomCreatePayload = {
  __typename?: 'RoomCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  room: Room;
};

/** An edge in a connection. */
export type RoomEdge = {
  __typename?: 'RoomEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Room;
};

/** Autogenerated input type of RoomFollow */
export type RoomFollowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roomId: Scalars['ID']['input'];
};

/** Autogenerated return type of RoomFollow. */
export type RoomFollowPayload = {
  __typename?: 'RoomFollowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  room: Room;
};

export type RoomInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** signedId of a presigned uploaded image */
  photo?: InputMaybe<Scalars['String']['input']>;
  roomStatus?: InputMaybe<RoomStatus>;
};

/** Autogenerated input type of RoomJoin */
export type RoomJoinInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roomId: Scalars['ID']['input'];
};

/** Autogenerated return type of RoomJoin. */
export type RoomJoinPayload = {
  __typename?: 'RoomJoinPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  room: Room;
};

/** Autogenerated input type of RoomLeave */
export type RoomLeaveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roomId: Scalars['ID']['input'];
};

/** Autogenerated return type of RoomLeave. */
export type RoomLeavePayload = {
  __typename?: 'RoomLeavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  room: Room;
};

/** Autogenerated input type of RoomRemoveMember */
export type RoomRemoveMemberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roomId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of RoomRemoveMember. */
export type RoomRemoveMemberPayload = {
  __typename?: 'RoomRemoveMemberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  room: Room;
};

/** Room status enum */
export enum RoomStatus {
  Archived = 'ARCHIVED',
  Published = 'PUBLISHED'
}

/** Autogenerated input type of RoomUnfollow */
export type RoomUnfollowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roomId: Scalars['ID']['input'];
};

/** Autogenerated return type of RoomUnfollow. */
export type RoomUnfollowPayload = {
  __typename?: 'RoomUnfollowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  room: Room;
};

/** Autogenerated input type of RoomUpdate */
export type RoomUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  roomInput: RoomInput;
};

/** Autogenerated return type of RoomUpdate. */
export type RoomUpdatePayload = {
  __typename?: 'RoomUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  room: Room;
};

export type RoomUserFilterInput = {
  roomAssociationType: RoomAssociation;
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of SetTypingIndication */
export type SetTypingIndicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roomId: Scalars['ID']['input'];
};

/** Autogenerated return type of SetTypingIndication. */
export type SetTypingIndicationPayload = {
  __typename?: 'SetTypingIndicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SmsUpdatePhone */
export type SmsUpdatePhoneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

/** Autogenerated return type of SmsUpdatePhone. */
export type SmsUpdatePhonePayload = {
  __typename?: 'SmsUpdatePhonePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of SmsUserAuthChallenge */
export type SmsUserAuthChallengeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
};

/** Autogenerated return type of SmsUserAuthChallenge. */
export type SmsUserAuthChallengePayload = {
  __typename?: 'SmsUserAuthChallengePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SmsUserAuth */
export type SmsUserAuthInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
};

/** Autogenerated return type of SmsUserAuth. */
export type SmsUserAuthPayload = {
  __typename?: 'SmsUserAuthPayload';
  authToken?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type Subscription = {
  __typename?: 'Subscription';
  messageEvent: MessageEventPayload;
  notifications: NotificationsPayload;
  typingIndication: TypingIndicationPayload;
};


export type SubscriptionMessageEventArgs = {
  parentMessageId?: InputMaybe<Scalars['ID']['input']>;
  roomId?: InputMaybe<Scalars['ID']['input']>;
};


export type SubscriptionNotificationsArgs = {
  userId: Scalars['ID']['input'];
};


export type SubscriptionTypingIndicationArgs = {
  roomId: Scalars['ID']['input'];
};

export enum TranscodingState {
  Completed = 'COMPLETED',
  Error = 'ERROR',
  Initial = 'INITIAL'
}

/** Autogenerated return type of TypingIndication. */
export type TypingIndicationPayload = {
  __typename?: 'TypingIndicationPayload';
  activeAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UploadPresign */
export type UploadPresignInput = {
  byteSize: Scalars['Int']['input'];
  checksum: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contentType: Scalars['String']['input'];
  filename: Scalars['String']['input'];
};

/** Autogenerated return type of UploadPresign. */
export type UploadPresignPayload = {
  __typename?: 'UploadPresignPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  headers?: Maybe<Scalars['JSON']['output']>;
  signedId?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type User = Node & {
  __typename?: 'User';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  displayOrUsername: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  hasAcceptedTerms?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isOnboarded?: Maybe<Scalars['Boolean']['output']>;
  notificationConfig?: Maybe<NotificationConfig>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profilePictureColor: ProfilePictureColor;
  userRole?: Maybe<UserRole>;
  username?: Maybe<Scalars['String']['output']>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges: Array<UserEdge>;
  /** A list of nodes. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: User;
};

export type UserInput = {
  /** signedId of a presigned uploaded image */
  avatar?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fcmToken?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  userStatus?: InputMaybe<UserStatus>;
};

/** Autogenerated input type of UserOnboard */
export type UserOnboardInput = {
  acceptTerms?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  completeOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  userInput?: InputMaybe<UserOnboardingInput>;
};

/** Autogenerated return type of UserOnboard. */
export type UserOnboardPayload = {
  __typename?: 'UserOnboardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  user: User;
};

export type UserOnboardingInput = {
  /** signedId of a presigned uploaded image */
  avatar?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** User role enum */
export enum UserRole {
  Default = 'DEFAULT',
  SystemAdmin = 'SYSTEM_ADMIN'
}

/** User status enum */
export enum UserStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Invited = 'INVITED'
}

/** Autogenerated input type of UserUpdate */
export type UserUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  userInput: UserInput;
};

/** Autogenerated return type of UserUpdate. */
export type UserUpdatePayload = {
  __typename?: 'UserUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  user: User;
};

export type CachedViewerContextFragment = { __typename?: 'User', id: string, userRole?: UserRole | null };

export type LogoutMutationVariables = Exact<{
  input: LogoutInput;
}>;


export type LogoutMutation = { __typename?: 'Mutation', logout: { __typename?: 'LogoutPayload', success: boolean } };

export type ViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, userRole?: UserRole | null } | null };

export type LoginMutationVariables = Exact<{
  input: CredentialsUserAuthInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'CredentialsUserAuthPayload', user?: { __typename?: 'User', id: string } | null } };

export type EmailTokenAuthMutationVariables = Exact<{
  input: EmailTokenUserAuthInput;
}>;


export type EmailTokenAuthMutation = { __typename?: 'Mutation', emailTokenUserAuth: { __typename?: 'EmailTokenUserAuthPayload', success: boolean, csrfToken: string } };

export type EmailAuthChallengeMutationVariables = Exact<{
  input: EmailUserAuthChallengeInput;
}>;


export type EmailAuthChallengeMutation = { __typename?: 'Mutation', emailUserAuthChallenge: { __typename?: 'EmailUserAuthChallengePayload', success: boolean } };

export type UpdateProfileMutationVariables = Exact<{
  input: UserUpdateInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', userUpdate: { __typename?: 'UserUpdatePayload', user: { __typename?: 'User', id: string } } };

export type UserDetailsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type UserDetailsQuery = { __typename?: 'Query', node?: { __typename?: 'Message' } | { __typename?: 'NotificationConfig' } | { __typename?: 'Room' } | { __typename?: 'User', id: string, userRole?: UserRole | null } | null };

export const CachedViewerContextFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CachedViewerContext"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"userRole"}}]}}]} as unknown as DocumentNode<CachedViewerContextFragment, unknown>;
export const LogoutDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Logout"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LogoutInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"logout"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const ViewerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"CachedViewerContext"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CachedViewerContext"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"userRole"}}]}}]} as unknown as DocumentNode<ViewerQuery, ViewerQueryVariables>;
export const LoginDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Login"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CredentialsUserAuthInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"login"},"name":{"kind":"Name","value":"credentialsUserAuth"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const EmailTokenAuthDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EmailTokenAuth"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EmailTokenUserAuthInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"emailTokenUserAuth"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"csrfToken"}}]}}]}}]} as unknown as DocumentNode<EmailTokenAuthMutation, EmailTokenAuthMutationVariables>;
export const EmailAuthChallengeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EmailAuthChallenge"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EmailUserAuthChallengeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"emailUserAuthChallenge"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<EmailAuthChallengeMutation, EmailAuthChallengeMutationVariables>;
export const UpdateProfileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateProfile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserUpdateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userUpdate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const UserDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"userDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"userRole"}}]}}]}}]}}]} as unknown as DocumentNode<UserDetailsQuery, UserDetailsQueryVariables>;